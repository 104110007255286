@import '~antd/dist/antd.css';

:root {
  --color-bg: linear-gradient(to right, #bfbfbf, #27736c, #060b22);
  --color-font: white;
  --color-bg-paper: white;
}

input {
  font-size: 16px;
}

@font-face {
  font-family: "Dancing";
  src: url("../public/fonts/DancingScript-Regular.ttf");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "DancingBold";
  src: url("../public/fonts/DancingScript-Bold.ttf");
  font-weight: bold;
  font-display: swap;
}

.f-dancing {
  font-family: 'Dancing';
}

.f-dancing-bold {
  font-family: 'DancingBold';
}

.f-mono {
  font-family: monospace;
}

.f-white {
  color: var(--color-font);
}

.error-page {
  text-align: center;
}


.main-color {
  background: var(--color-bg);
  color: var(--color-font);
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-90 {
  height: calc(90%);
}

.h-80 {
  height: calc(80%);
}

.h-20 {
  height: calc(20%);
}

.h-10 {
  height: calc(10%);
}

.max-height {
  height: 100vh;
}

.min-height {
  min-height: 100vh;
}

.p-15 {
  padding: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.m-t-b-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.p-l-r-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-t-b-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.sub-title {
  font-size: small;
  font-style: italic;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-0 {
  margin: 0;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-10 {
  margin-right: 10px;
}


.boradius-10 {
  border-radius: 10px;
}

.f-w-bold {
  font-weight: bold;
}

.p_wrap {
  white-space: pre-line;
}

.box-profile {
  box-shadow: 0px 0px 9px 6px var(--color-bg);
  border-radius: 7px;
  padding: 20px;
}

.m-h-auto {
  margin: auto 0;
}

.txt-right {
  text-align: right;
}

.txt-bold {
  font-weight: bold;
}

.text-none {
  text-decoration: none;
}

.btn-link {
  color: black;
  font-size: large;
  font-family: monospace;
}

.btn-link:hover {
  color: black;
  text-shadow: 1px 1px 1px white;
  transition: 0.3s;
}

.btn-link:not(:hover) {
  color: black;
  transition: 0.3s;
}

.footer {
  position: absolute;
  bottom: 0;
}

.cursor-p {
  cursor: pointer;
}

.title-cvs {
  font-size: x-large;
  font-weight: bold;
}

.alert {
  position: absolute;
  width: 100%;
  z-index: 1001;
}

.box-chat {
  overflow-y: scroll;
  height: calc(75vh);
  word-break: break-word;
}

.box-chat-mobile {
  overflow-y: scroll;
  height: calc(60vh);
}

.box-chat-position {
  position: relative;
  top: calc(15vh);
}

.text-editor-position {
  position: relative;
  top: calc(15vh);
}

.p-fixed {
  position: fixed;
}

.ico-sign-out {
  float: right;
  padding-top: 8px;
  cursor: pointer;
}

.ico-sign-out :hover {
  transition: 0.4s;
  color: black;
}

.ico-sign-out :not(:hover) {
  transition: 0.2s;
}

.f-size-larger {
  font-size: larger;
}

.justify-center {
  justify-content: center;
}

.color-wh {
  color: var(--color-font);
}

.p-0 {
  padding: 0
}

.utik-content-card {
  font-size: 2em;
  font-weight: bold;
}

.utik-avatar-card {
  width: 4em;
}

.color-number-lottery {
  color: #fa4b4b;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-10 {
  margin-top: 10px;
}

.vertical-text {
  width: 0;
  display: block;
}

.bg-white {
  background-color: var(--color-bg-paper);
}

.userticketgrid-title {
  width: 100%;
  background-color: var(--color-bg-paper);
}

.userticketgrid-title>h4 {
  padding: 10px 20px 10px;
  margin: 0;
}

.userticketgrid-title>div {
  margin: 0;
}

.login-container {
  margin: auto
}

.login-bottom {
  justify-content: end;
  display: flex;
  margin-top: 10px;
}

.login-bottom>button:first-child {
  margin-right: 10px;
}

.signup-bottom>button:first-child {
  margin-right: 10px;
}

.signup-container {
  margin: auto;
}

.signup-bottom {
  justify-content: end;
  display: flex;
  margin-top: 10px;
}

.sider-fixed {
  width: 300px;
  position: fixed;
}