:root {
  --color-lottery-table-hover: #fbef3d8f;
}


.txt-center {
    text-align: center;
}

.dp-inline-flex {
    display: inline-flex;
}

.aglin-items-center {
    align-items: center;
}

.m-l-10 {
    margin-left: 10px;
}

.country-box-active {
    box-shadow: 3px 7px 13px 0px;
    transition: 600ms;
}

.country-box:hover {
    box-shadow: 3px 7px 13px 0px;
    transition: 600ms;
}

.country-box:not(:hover) {
    box-shadow: 0;
    transition: 600ms;
}
.country-box {
    cursor: pointer;
}

.disabled-link {
  pointer-events: none;
}

.mw-100 {
    width: 100%;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.lottery-hint {
    display: flex;
    align-items: center;
}

.lottery-hint > div {
    width: 100px;
}

.lottery-grid {
    padding-right: 10px;
}

.lottery-card-won {
    background-color: #0dea447a;
    text-align: center;
    border-radius: 0px 35px;
    padding: 10px 0;
}

.curp {
    cursor: pointer;
}

.lottery-card {
    background-color: #cccccc7a;
    text-align: center;
    border-radius: 0px 35px;
    padding: 10px 0;
}

.lottery-number {
    font-size: 1.5em;
}

.lottery-card-lose {
    background-color: #ff000069;
    text-align: center;
    border-radius: 0px 35px;
    padding: 10px 0;
}

.utik-tools {
    margin: 5px 0 20px 0;
    display: flex;
    justify-content: flex-end;
}

.status-new {
 background-color: none;
}

.status-lose {
 background-color: #ff000069;
}
.status-won {
 background-color: #0dea447a;
}

.aniwave {
    animation: popDiv 5s linear infinite;
}

.lty-day {
   width: 76px;
}

.lty-eighth {
    font-size: 2rem;
    color: red;
    font-weight: bold;
    border: 1px solid #999;
}

.lty-eighth :hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-eighth > td > div {
    max-height: 46px;
    min-height: 46px;
}

.lty-seventh {
    font-weight: bold;
    border: 1px solid #999;
}
.lty-seventh :hover{
    background-color: var(--color-lottery-table-hover);
}

.lty-sixth {
    font-weight: bold;
    border: 1px solid #999;
}
.lty-sixth > td > div:hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-fiveth {
    font-weight: bold;
    border: 1px solid #999;
}
.lty-fiveth:hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-fourth {
    font-weight: bold;
    border: 1px solid #999;
}
.lty-fourth > td > div:hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-threeth {
    font-weight: bold;
    border: 1px solid #999;
}
.lty-threeth > td > div:hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-second {
    font-weight: bold;
    border: 1px solid #999;
    
}
.lty-second:hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-first {
    font-weight: bold;
    border: 1px solid #999;    
}
.lty-first:hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-special {
    font-weight: bold;
    border: 1px solid #999;
    color: red; 
    font-size: 1.5rem;
}
.lty-special:hover {
    background-color: var(--color-lottery-table-hover);
}

.lty-special > td > div {
    max-height: 46px;
    min-height: 46px;
    max-width: 100px;
    min-width: 100px;
}

.lty-border {
    border: 1px solid #999;
}

.lty-bg {
    background: #F3F3F3;
}

.lty-active {
    background-color: var(--color-lottery-table-hover);
}

.lty-bot-btn {
    display: inline-flex;
}
.lty-bot-btn > div {
    border-right: 1px solid #9E9E9E;
    padding: 0 10px;
    cursor: pointer;
}

.lty-bot-btn > div:last-child {
    border: none;
    padding: 0 10px
}

.lty-bot-tr {
    padding: 0;
    border: 1px solid #999;
    background-color: var(--color-lottery-table-hover);
}

.lty-bot-table {
    position: inherit;
    left: -1px;
    bottom: 0px;
}

.dgLty-number {
    padding: 0;
}

.dgLty-number > li {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 5px;
    font-weight: bold;
    margin: 10px 15px 10px 0;
}

.dgLty-number > li > p {
  border: 2px solid black;
  border-radius: 50%;
  font-size: 35px;
  width: 57px;
  padding: 0px 7px;
}

.dgLty-number > li:nth-child(7) {
    border-left: 2px solid red;
}

@keyframes popDiv { 
    0%   {transform:  rotate(0deg);}
    25%  {transform:  rotate(10deg);}
    50%  {transform:  rotate(-10deg);}
    100% {transform:  rotate(0deg);}
}

@media only screen and (max-width: 767px) {
    .country-box {
        padding-left: 20px;
    }
    .country-box-active {
        padding-left: 20px;
    }
    .lottery-grid {
        padding-left: 0;
        padding-right: 0;
    }
    .lty-special > td > div {
        max-height: 46px;
        min-height: 46px;
        max-width: 70px;
        min-width: 70px;
    }
    .lty-special {
        font-size: 1rem;
    }
    .lot-central-table {
        margin: 50px 0;
    }
}

@media only screen and (min-width: 768px) {
    .country-box {
        text-align: center;
    }
    .country-box-active {
        text-align: center;
    }
}